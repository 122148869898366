import React, { useEffect, useState } from "react"

import {
  getEncryptedSecret,
  getPublicKey,
} from "../services/localstorage.service"

const SessionContext = React.createContext({
  isLoggedIn: false,
  sessionIsActive: false,
  publicKey: "",
  username: "",
})

const SessionProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [sessionIsActive, setSessionIsActive] = useState(false)
  const [publicKey, setPublicKey] = useState("")
  const [username, setUsername] = useState("")

  const wipeSession = () => {
    setIsLoggedIn(false)
    setSessionIsActive(false)
    setPublicKey("")
    setUsername("")
  }

  useEffect(() => {
    if (getEncryptedSecret() && getPublicKey()) {
      setIsLoggedIn(true)
      setPublicKey(getPublicKey())
    }
  }, [])

  return (
    <SessionContext.Provider
      value={{
        isLoggedIn,
        sessionIsActive,
        username,
        publicKey,
        setIsLoggedIn,
        setSessionIsActive,
        setUsername,
        setPublicKey,
        wipeSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export default SessionContext

export { SessionProvider }
