/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import "./src/styles/global.css"

import { SessionProvider } from "./src/context/session.context"
import { ThemeProvider } from "./src/context/theme-context"
import { WalletProvider } from "./src/context/wallet.context"

export const wrapRootElement = ({ element }) => (
  <SessionProvider>
    <WalletProvider>
      <ThemeProvider>{element}</ThemeProvider>
    </WalletProvider>
  </SessionProvider>
)
