import React, { useEffect, useState } from "react"

import * as localstorageService from "../services/localstorage.service"

const ThemeContext = React.createContext({
  theme: "light",
  toggleTheme: theme => {},
})

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light")

  useEffect(() => setTheme(localstorageService.getTheme()), [])

  const handleTheme = theme => {
    localstorageService.setTheme(theme)
    setTheme(theme)
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme: handleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext

export { ThemeProvider }
