// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-es-js": () => import("./../../../src/pages/404.es.js" /* webpackChunkName: "component---src-pages-404-es-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-devland-js": () => import("./../../../src/pages/about-devland.js" /* webpackChunkName: "component---src-pages-about-devland-js" */),
  "component---src-pages-about-devp-js": () => import("./../../../src/pages/about-devp.js" /* webpackChunkName: "component---src-pages-about-devp-js" */),
  "component---src-pages-about-snippet-js": () => import("./../../../src/pages/about-snippet.js" /* webpackChunkName: "component---src-pages-about-snippet-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-acerca-de-devland-es-js": () => import("./../../../src/pages/acerca-de-devland.es.js" /* webpackChunkName: "component---src-pages-acerca-de-devland-es-js" */),
  "component---src-pages-acerca-de-devp-es-js": () => import("./../../../src/pages/acerca-de-devp.es.js" /* webpackChunkName: "component---src-pages-acerca-de-devp-es-js" */),
  "component---src-pages-acerca-de-snippet-es-js": () => import("./../../../src/pages/acerca-de-snippet.es.js" /* webpackChunkName: "component---src-pages-acerca-de-snippet-es-js" */),
  "component---src-pages-cuenta-es-js": () => import("./../../../src/pages/cuenta.es.js" /* webpackChunkName: "component---src-pages-cuenta-es-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-templates-blog-template-en-js": () => import("./../../../src/templates/blogTemplate.en.js" /* webpackChunkName: "component---src-templates-blog-template-en-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

