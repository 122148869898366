import React, { useContext, useState } from "react"

import {
  assetCode,
  getBalance,
  getStellarAccount,
  isTrustlineEnabled,
} from "../services/stellar.service"

import SessionContext from "./session.context"

const WalletContext = React.createContext({
  publicKey: "",
  account: {},
})

const WalletProvider = ({ children }) => {
  const { publicKey } = useContext(SessionContext)
  const [stellarAccountIsActive, setStellarAccountIsActive] = useState(false)
  const [devpTrustlineEnabled, setDevpTrustlineEnabled] = useState(false)
  const [balances, setBalances] = useState({})

  const loadWallet = async () => {
    let account
    try {
      account = await getStellarAccount(publicKey)
      if (account) {
        setStellarAccountIsActive(true)
        setBalances({
          xlm: await getBalance(account, "native"),
          devp: await getBalance(account, assetCode),
        })
        setDevpTrustlineEnabled(await isTrustlineEnabled(account))
      }
    } catch (error) {
      console.log(error)
      setStellarAccountIsActive(false)
      setBalances({ xlm: 0, devp: 0 })
      setDevpTrustlineEnabled(false)
    }
  }

  return (
    <WalletContext.Provider
      value={{
        xlmBalance: balances.xlm,
        devpBalance: balances.devp,
        stellarAccountIsActive,
        devpTrustlineEnabled,
        loadWallet,
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}

export default WalletContext

export { WalletProvider }
