const LOCAL_STORAGE_KEYS = {
  ENCRYPTED_SECRET: "ENCRYPTED_SECRET",
  PUBLIC_KEY: "PUBLIC_KEY",
  JWT: "jwt",
  THEME: "theme",
}

export const setEncryptedSecret = encryptedSecret =>
  localStorage.setItem(LOCAL_STORAGE_KEYS.ENCRYPTED_SECRET, encryptedSecret)

export const getEncryptedSecret = () =>
  localStorage.getItem(LOCAL_STORAGE_KEYS.ENCRYPTED_SECRET)

export const removeEncryptedSecret = () =>
  localStorage.removeItem(LOCAL_STORAGE_KEYS.ENCRYPTED_SECRET)

export const setPublicKey = publicKey =>
  localStorage.setItem(LOCAL_STORAGE_KEYS.PUBLIC_KEY, publicKey)

export const getPublicKey = () =>
  localStorage.getItem(LOCAL_STORAGE_KEYS.PUBLIC_KEY)

export const removePublicKey = () =>
  localStorage.removeItem(LOCAL_STORAGE_KEYS.PUBLIC_KEY)

export const clearLocalStorage = () => localStorage.clear()

export const setJwt = jwt => localStorage.setItem(LOCAL_STORAGE_KEYS.JWT, jwt)

export const getJwt = () => localStorage.getItem(LOCAL_STORAGE_KEYS.JWT)

export const removeJwt = () => localStorage.removeItem(LOCAL_STORAGE_KEYS.JWT)

export const setTheme = theme =>
  localStorage.setItem(LOCAL_STORAGE_KEYS.THEME, theme)

export const getTheme = () => localStorage.getItem(LOCAL_STORAGE_KEYS.THEME)
