// const StellarSdk = require('stellar-sdk');

import {
  Asset,
  Keypair,
  Operation,
  Server,
  TransactionBuilder,
  Networks,
} from "stellar-sdk"

const HORIZON_URL =
  process.env.GATSBY_STELLAR_NETWORK === "PUBLIC"
    ? "https://horizon.stellar.org"
    : "https://horizon-testnet.stellar.org"

const server = new Server(HORIZON_URL)
export const assetCode = process.env.GATSBY_ASSET_CODE || "DEVP"
const assetIssuer =
  process.env.GATSBY_ASSET_ISSUER ||
  "GBJZ2J6JJLWK2PND4ZAWTYKMRAOLDZPOUMZFY6ISIQPOY6HFOL5TIPCR"
const assetDEVP = new Asset(assetCode, assetIssuer)

export const createKeyPair = () => {
  return Keypair.random()
}

export const getKeyPair = secret => {
  return Keypair.fromSecret(secret)
}

export const accountIsActive = async publicKey => {
  try {
    await server.loadAccount(publicKey)
    return true
  } catch {
    return false
  }
}

export const getStellarAccount = async publicKey => {
  try {
    return await server.loadAccount(publicKey)
  } catch {
    throw Error("Account not active")
  }
}

export const getBalance = async (account, asset_code = "native") => {
  try {
    let balance
    if (asset_code === "native") {
      balance = account.balances.find(asset => asset.asset_type === asset_code)
    } else {
      balance = account.balances.find(asset => asset.asset_code === asset_code)
    }

    return balance.balance
  } catch {
    return 0
  }
}

export const getFee = async () => {
  try {
    const fee = await server.feeStats()
    return fee.max_fee.mode
  } catch {
    throw Error("Error getting fees")
  }
}

export const isTrustlineEnabled = async account => {
  try {
    const balances = await account.balances
    return !!balances.filter(
      balance =>
        balance.asset_type === "credit_alphanum4" &&
        balance.asset_code === assetCode &&
        balance.asset_issuer === assetIssuer &&
        balance.is_authorized
    ).length
  } catch (e) {
    console.log(e)
    return false
  }
}

export const createDataOperation = async (name, value) => {
  return Operation.manageData({
    name,
    value,
  })
}

export const enableDEVPTrust = async keyPair => {
  try {
    const account = await getStellarAccount(keyPair.publicKey())
    const trustlineEnabled = await isTrustlineEnabled(account)
    if (trustlineEnabled) {
      throw Error("Trustline already enabled")
    }
    const changeTrustTx = new TransactionBuilder(account, {
      fee: await getFee(),
      networkPassphrase:
        process.env.GATSBY_STELLAR_NETWORK === "PUBLIC"
          ? Networks.PUBLIC
          : Networks.TESTNET,
    })
      .addOperation(
        // The `changeTrust` operation creates (or alters) a trustline.
        Operation.changeTrust({
          asset: assetDEVP,
          limit: "1000000",
        })
      )
      .setTimeout(100)
      .build()
    changeTrustTx.sign(keyPair)
    const txResult = await server.submitTransaction(changeTrustTx)
    return txResult
  } catch (e) {
    console.log("Error when enabling trustline. " + e)
    return false
  }
}

export const getDEVPPrice = async () => {
  console.log(await server.orderbook(assetDEVP, Asset.native()).call())
}
